import { Row, Col, Card, Typography } from "antd";
import "./homePage.css";
import { useEffect, useState } from "react";

const ONE_DAY = 1000 * 60 * 60 * 24;
const ONE_WEEK = ONE_DAY * 7;
const ONE_MONTH = ONE_DAY * 30; // Aproximado

export default function HomePage() {
  // Fecha final del contador (8 de septiembre de 2024)
  const deadline = new Date("2025-03-09T00:00:00").getTime();

  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    weeks: 0,
    months: 0,
  });

  // Añadido la funcionalidad para determinar el estado del evento
  const now = new Date();

  const getEventStatus = (eventDate: Date): string => {
    const eventYear = eventDate.getFullYear();
    const eventMonth = eventDate.getMonth();

    const currentYear = now.getFullYear();
    const currentMonth = now.getMonth();

    if (
      eventYear < currentYear ||
      (eventYear === currentYear && eventMonth < currentMonth)
    ) {
      return "past";
    }

    if (eventYear === currentYear && eventMonth === currentMonth) {
      return "current";
    }

    if (
      eventYear > currentYear ||
      (eventYear === currentYear && eventMonth > currentMonth)
    ) {
      return "future";
    }

    return "unknown";
  };

  const electionEvents = [
    {
      date: new Date("2021-12-01"),
      event:
        "Inicia el plazo para la inscripción de nuevos partidos políticos.",
    },
    {
      date: new Date("2024-03-01"),
      event:
        "Los Partidos Políticos deberán presentar informe de cumplimiento de la política de equidad de género aprobada internamente por cada uno de ellos.",
    },
    {
      date: new Date("2024-03-01"),
      event:
        "CNE Entrega a los Partidos Políticos de los Listados a cargos de elección popular, por nivel electivo.",
    },
    {
      date: new Date("2024-08-01"),
      event:
        "Finaliza el plazo de actualización domiciliaria para efectos electorales.",
    },
    {
      date: new Date("2024-09-01"),
      event:
        "Finaliza el plazo para la autorización de inscripción de nuevos partidos políticos, para participar en elecciones primarias 2025.",
    },
    {
      date: new Date("2024-10-01"),
      event:
        "Finaliza el plazo para la exhibición del listado preliminar de electores en la página web del Consejo Nacional Electoral y en los Registros Civiles Municipales.",
    },
    {
      date: new Date("2024-11-01"),
      event:
        "Inicia el plazo de solicitud de inscripción de candidatos de los movimientos políticos ante el Consejo Nacional Electoral por medio de la autoridad central de los partidos políticos.",
    },
    {
      date: new Date("2024-12-01"),
      event:
        "El Consejo Nacional Electoral (CNE) debe hacer de conocimiento público a través de su página web la notificación de cuantos movimientos solicitaron inscripción.",
    },
    {
      date: new Date("2025-01-01"),
      event: "Inicia el período de propaganda electoral.",
    },
    {
      date: new Date("2025-02-01"),
      event:
        "Finaliza el plazo para que el Consejo Nacional Electoral (CNE). por medio del Instituto Nacional de Formación Político Electoral, desarrolle los procesos de capacitación de los miembros de las Juntas Receptoras de Votos (JRV).",
    },
    {
      date: new Date("2025-03-01"),
      event: "Finaliza el plazo para realizar Propaganda Electoral",
    },
    {
      date: new Date("2025-04-01"),
      event:
        "Finaliza el plazo para que el Consejo Nacional Electoral, deba reponer la práctica de una elección que no se realizó.",
    },
    {
      date: new Date("2025-04-01"),
      event: "Disolución de los movimientos internos",
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date().getTime();
      const distance = deadline - now;

      const days = Math.floor(distance / ONE_DAY);
      const weeks = Math.floor(distance / ONE_WEEK);
      const months = Math.floor(distance / ONE_MONTH);

      setTimeLeft({ days, weeks, months });
    }, 1000);

    return () => clearInterval(timer);
  }, [deadline]);

  return (
    <div className="home-page">
      <img className="banner" src="assets/img/banner.jpg" alt="Banner" />
      <Row justify="center" align="middle" className="countdown-row">
        <Col span={24}>
          <Card className="countdown-card">
            <Typography.Title level={3}>
              Faltan {timeLeft.months} meses para las Elecciones Primarias en
              Honduras
            </Typography.Title>
          </Card>
          <Card className="countdown-card">
            <Typography.Title level={3}>
              Faltan {timeLeft.weeks} semanas para las Elecciones Primarias en
              Honduras
            </Typography.Title>
          </Card>
          <Card className="countdown-card">
            <Typography.Title level={3}>
              Faltan {timeLeft.days} días para las Elecciones Primarias en
              Honduras
            </Typography.Title>
          </Card>
        </Col>
      </Row>
      <Row className="election-events" justify="center">
        <Col span={24}>
          <Typography.Title level={2}>
            Fechas importantes en las elecciones:
          </Typography.Title>
          <ul>
            {electionEvents.map((event, index) => (
              <li
                key={index}
                className={`event-item ${getEventStatus(event.date)}`}
              >
                <Typography.Text strong>
                  {event.date.toLocaleDateString()}
                </Typography.Text>
                : {event.event}
              </li>
            ))}
          </ul>
        </Col>
      </Row>
    </div>
  );
}
